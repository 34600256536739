import * as amplitude from '@amplitude/analytics-browser'
import * as process from 'process'

const AMPLITUDE_DEVICE_KEY = 'amplitude_device_id'

const getStoredDeviceId = () => {
    return window.localStorage.getItem(AMPLITUDE_DEVICE_KEY)
}

const storeDeviceId = () => {
    const deviceId = amplitude.getDeviceId()
    // Only store if we have a valid device ID
    if (deviceId) {
        window.localStorage.setItem(AMPLITUDE_DEVICE_KEY, deviceId)
    }
}

export const initAmplitude = (uuid?: string, isTestAccount: boolean = false) => {
    if (process.env.NEXT_PUBLIC_ENVIRONMENT === 'production' && window) {
        const storedDeviceId = getStoredDeviceId()

        amplitude.init(process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY as string, uuid, {
            serverZone: 'EU',
            serverUrl: 'https://proxy.enter.de/mpltd',
            autocapture: { elementInteractions: true },
            deviceId: storedDeviceId || undefined, // Use stored device ID if available
        })

        // Store the device ID after initialization if we don't have one
        if (!storedDeviceId) {
            storeDeviceId()
        }

        // If we have both a UUID and a device ID, we can merge them
        if (uuid && storedDeviceId) {
            const identifyEvent = new amplitude.Identify()
            identifyEvent.set('is_test_user', isTestAccount || false)
            amplitude.identify(identifyEvent)
        }
    }
}

export const trackAmplitudeEvent = (
    eventName: string,
    eventProperties?: {
        service?: string
        button_text?: string
        platform?: string
        personalization?: string
        renovation_measure?: string
        offer_type?: string
        buildingUuid?: string
        surveyId?: string
        surveyStep?: string
        status?: string
        stage?: string
        responseId?: string
        pricePackageId?: string
        energyCheck?: string
        panelContent?: string
        source?: string
        leadUuid?: string
        externalPartnerId?: string
        externalAgentId?: string
        directIngestion?: boolean
    },
    userProperties?: {
        accountUuid?: string
    }
) => {
    if (process.env.NEXT_PUBLIC_ENVIRONMENT === 'production' && window) {
        if (userProperties?.accountUuid) {
            const identifyEvent = new amplitude.Identify()
            identifyEvent.set('accountUuid', userProperties?.accountUuid)

            // Ensure we maintain device ID when identifying user
            const deviceId = getStoredDeviceId()
            amplitude.identify(identifyEvent, {
                user_id: userProperties.accountUuid,
                device_id: deviceId || undefined,
            })
        }
        amplitude.track(eventName, eventProperties)
    } else {
        // eslint-disable-next-line no-console
        console.log('Amplitude event', eventName, eventProperties, userProperties)
    }
}

// should be unique, for internal app
export enum InternalAmplitudeCalendlyEvent {
    FINANCING_OFFER = 'financing_offer',
    ISFP_FOR_YOU_CTA = 'isfp_for_you_CTA',
    CONSTRUCTION_WORKER_OFFER = 'construction_worker_offer',
    RENOVATION_INTEREST_AD = 'renovation_interest_ad',
    SUBSIDY_SERVICE_FOR_YOU_CTA = 'subsidy_service_for_you_CTA',
    PV_CONSULTATION = 'PV consultation booking started',
    SUBSIDY_SERVICE_PRICE_PACKAGE_CTA = 'subsidy_service_price_package_CTA',
    ENERGY_SERVICE_PRICE_PACKAGE_CTA = 'energy_service_price_package_CTA',
    ENTER_SERVICE_PRICE_PACKAGE_CTA = 'enter_service_price_package_CTA',
}

// shared, send and matched to amplitude
export enum AmplitudeEventName {
    APPOINTMENT_BOOKING_STARTED = 'Appointment Booking Started',
    APPOINTMENT_PHONE_CALL_INITIATED = 'Appointment Phone Call Initiated',
    APP_OPENED = 'App Opened',
    ROBO_ADVICE_STARTED = 'Robo Advice Started',
    ROBO_ADVICE_OPENED = 'Robo Advice Opened',
    DASHBOARD_OPENED = 'Dashboard Opened',
    DOCUMENT_OVERVIEW_OPENED = 'Document Overview Opened',
    INSTALLATION_PARTNER_OVERVIEW_OPENED = 'Installation Partner Overview Opened',
    UPLOAD_DOCUMENTS_STARTED = 'Upload Documents Started',
    UPLOAD_DOCUMENTS_COMPLETED = 'Upload Documents Completed',
    RENOVATION_INTEREST_AD = 'Renovation Interest Ad openend',
    INSTALLATION_PARTNER_INITIAL_OFFER_VIEWED = 'Installation Partner Initial Offer Viewed',
    INSTALLATION_PARTNER_INITIAL_OFFER_PDF_DOWNLOADED = 'Installation Partner Initial Offer PDF Downloaded',
    INITIAL_OFFER_INFO_PDF_VIEWED = 'Viewed product info',
    CHANGE_WINDOW_OFFER_CLICKED = 'Change window offer clicked',
    ENERGY_CHECK_SUMMARY_PAGE_VIEWED = 'Energy Check Summary Page Viewed',
    PV_CONSULTATION_WIDGET_LOADED = 'PV Consultation Widget Loaded',
    INITIAL_OFFER_OVERVIEW_PAGE_VIEWED = 'Initial Offer Overview Page Viewed',
    INITIAL_OFFER_OVERVIEW_CARD_CLICKED = 'Initial Offer Overview Card Clicked',
    INITIAL_OFFER_AMORTISATION_GRAPH_LOADED = 'Initial Offer Amortisation Graph Loaded',
    INITIAL_OFFER_SUBSIDY_INFO_CLICKED = 'Initial Offer Subsidy Info Clicked',
    PRICE_PACKAGES_WIDGET_LOADED = 'Price Packages Widget Loaded',
    PRICE_PACKAGE_INFO_CLICKED = 'Price Package Info Clicked',
    INFO_PANEL_OPENED = 'Info Panel Opened',
    INFO_PANEL_CLOSED = 'Info Panel Closed',

    SURVEY_READY = 'Survey Ready',
    SURVEY_STARTED = 'Survey Started',
    SURVEY_NEXT_QUESTION = 'Survey Next Question',
    SURVEY_SUBMITTED = 'Survey Submitted',
    SURVEY_CLOSED = 'Survey Closed',
    SURVEY_ENDED = 'Survey Ended',

    ORDER_CONFIRMATION_PAGE_VIEW = 'Enter Pro Order Confirmation Viewed',
    ORDER_CONFIRMATION_CTA = 'Enter Pro Order Confirmed',
}

export enum AmplitudePropertyServiceValue {
    RENOVATION_PLAN = 'renovation plan',
    SUBSIDY_SERVICE = 'subsidy service',
    FINANCING_OFFER = 'financing offer',
    INSTALLATION_PARTNER_QUOTE = 'installation partner quote',
    ROBO_ADVICE = 'robo advice',
    ENERGY_CHECK = 'energy check',
}

export enum AmplitudePropertyPlatformValue {
    CUSTOMER_PORTAL = 'customer portal',
}

export enum AmplitudePropertyPersonalizationValue {
    RENOVATION_INTEREST_SHOWN = 'renovation interest shown',
}

export enum AmplitudePropertyButtonTextValue {
    INITIAL_CONSULTATION = 'Jetzt Termin vereinbaren',
}
