import Text from '@design-system/components/text/text'
import PasscodeLockIcon from '@design-system/enter-icons/svg/passcode-lock.svg'
import { css } from '@emotion/react'
import Image from 'next/image'
import Lottie from 'react-lottie'
import gradientAnimationLottie from './lotties/gradient-bar-animation.json'

const Loader = () => {
    return (
        <div css={containerStyles}>
            <Image src="/enter-logo-black.png" width={107} height={30} objectFit="fill" alt="" css={logoStyles} />
            <PasscodeLockIcon />
            <Text size="medium" variant="body" css={textStyles}>
                Sie werden sicher angemeldet
            </Text>
            <Lottie
                options={{
                    loop: true,
                    autoplay: true,
                    animationData: gradientAnimationLottie,
                }}
                height={20}
                width={280}
                style={{
                    overflow: 'hidden',
                    borderRadius: 10,
                }}
            />
        </div>
    )
}

export default Loader

const containerStyles = css`
    background-color: #ededf2;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    justify-content: center;
    gap: 16px;
    position: relative;
    margin: -8px -8px;
`

const logoStyles = css`
    position: absolute;
    top: 38px;
`

const textStyles = css`
    font-family: '__poppins_d3f8bb', '__poppins_Fallback_d3f8bb';
    font-weight: 400;
    color: #464a54;
`
