import { createContext, useState, useEffect, useContext } from 'react'

import { GTMEvent, GTMSessionDataStorageKey } from '@design-system/types/GTMTypes'
import { GetBuildings } from '@design-system/types/buildings/buildingsTypes'
import { ChildrenProps } from '@design-system/types/propTypes'
import { sendGTMEvent } from '@design-system/utils/googleTagManager'
import { UserType, getCustomerType } from '@common/helpers/building/getCustomerType'
import { AppEntryPage } from '@common/types/form/types'

import {
    AmplitudeEventName,
    AmplitudePropertyPlatformValue,
    initAmplitude,
    trackAmplitudeEvent,
} from '@design-system/utils/amplitude'
import { getUserInformation } from '@design-system/utils/dataDog'
import { sendUserInformationToDataDog } from '@design-system/utils/dataDog'
import { Amplify } from 'aws-amplify'

export type UserStateType = { user_type: string; login_state: boolean }

export type GTMUserStatusContextType = UserStateType & {
    setUserType: (type: UserStateType) => void
}

export const UserStatusContext = createContext<GTMUserStatusContextType>({
    user_type: '',
    login_state: false,
    setUserType: () => {
        return
    },
})

export const UserStatusContextProvider = ({ children }: ChildrenProps) => {
    const [userType, setUserType] = useState<UserStateType>({
        user_type: '',
        login_state: false,
    })

    useEffect(() => {
        const sessionUserType = window?.sessionStorage.getItem(GTMSessionDataStorageKey)

        if (sessionUserType) {
            const cachedUserType = JSON.parse(sessionUserType)
            setUserType(cachedUserType)
        }
    }, [])

    useEffect(() => {
        sendUserInformationToDataDog()
        const userInfo = getUserInformation()
        const { id: userUUID, email } = JSON.parse(userInfo || '{}')
        const isTestAccount = email?.toLowerCase().includes('@enter') || email?.toLowerCase().includes('@radyant')
        initAmplitude(userUUID as string, isTestAccount)
        trackAmplitudeEvent(AmplitudeEventName.APP_OPENED, {
            platform: AmplitudePropertyPlatformValue.CUSTOMER_PORTAL,
        })
        Amplify.configure({
            API: {
                Events: {
                    endpoint: 'https://pg3cyomemfbe7e6zc2dbtokii4.appsync-api.eu-central-1.amazonaws.com/event',
                    region: 'eu-central-1',
                    defaultAuthMode: 'apiKey',
                    apiKey: process.env.NEXT_PUBLIC_AMPLIFY_API_KEY,
                },
            },
        })
    }, [userType.login_state, window])

    const handleSetUserType = (newState: UserStateType) => {
        setUserType(newState)
        window.sessionStorage.setItem(GTMSessionDataStorageKey, JSON.stringify(newState))
    }

    return (
        <UserStatusContext.Provider
            value={{ user_type: userType.user_type, login_state: userType.login_state, setUserType: handleSetUserType }}
        >
            {children}
        </UserStatusContext.Provider>
    )
}

export const useGtmInitUser = ({
    appEntryPage,
    isDataLoading,
    building,
}: {
    appEntryPage: AppEntryPage
    isDataLoading?: boolean
    building?: GetBuildings
}) => {
    const customerType = getCustomerType(building)

    const userStatus = useContext(UserStatusContext)

    useEffect(() => {
        if (isDataLoading) return

        switch (appEntryPage) {
            case AppEntryPage.LOGIN:
            case AppEntryPage.UNAUTHENTICATED_FORM:
                if (userStatus.user_type === UserType.VISITOR) break

                sendGTMEvent(GTMEvent.USER_INIT, customerType, {
                    user_type: customerType,
                    login_state: false,
                })

                userStatus.setUserType({ user_type: customerType, login_state: false })
                break
            default:
                if (userStatus.user_type === customerType) break

                sendGTMEvent(GTMEvent.USER_INIT, customerType, {
                    user_type: customerType,
                    login_state: true,
                })

                userStatus.setUserType({
                    user_type: customerType,
                    login_state: true,
                })

                break
        }
    }, [appEntryPage, customerType, isDataLoading, userStatus])
}
