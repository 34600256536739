import { AUTH_CUSTOM_TOKEN, AUTH_USER_UUID, USER_CUSTOM_TOKEN } from '@design-system/utils/authentication'
import {
    isUserLoggedInWithUserUUID,
    validateAndSignInWithCustomToken,
} from '@design-system/utils/firebaseAuthentication'
import { ReactNode, useCallback, useEffect, useState } from 'react'
import { datadogRum } from "@datadog/browser-rum";
import Loader from './Loader'

type FirebaseCustomAuthenticationProps = {
    children: ReactNode
    authCustomToken: string
    authUserUUID: string
}

export const FirebaseCustomAuthentication = ({
    children,
    authCustomToken,
    authUserUUID,
}: FirebaseCustomAuthenticationProps) => {
    const [error, setError] = useState<unknown>(undefined)

    const redirectToSamePathWithoutQueryParams = () => {
        const url = new URL(window.location.href)
        url.searchParams.delete(USER_CUSTOM_TOKEN)
        url.searchParams.delete(AUTH_CUSTOM_TOKEN)
        url.searchParams.delete(AUTH_USER_UUID)

        window.location.href = url.toString()
    }

    const signInCallback = useCallback((token: string, userUUID: string) => {
        if (isUserLoggedInWithUserUUID(userUUID)) {
            redirectToSamePathWithoutQueryParams()
            return
        }

        validateAndSignInWithCustomToken(token, '', userUUID)
            .then(() => {
                redirectToSamePathWithoutQueryParams()
            })
            .catch((e) => {
                setError(e)
                datadogRum.addError(error)
            })
    }, [])

    useEffect(() => {
        if (!authCustomToken) return

        signInCallback(authCustomToken, authUserUUID)
    }, [authCustomToken, authUserUUID, signInCallback])

    if (error) return `Error signing in ${error}`

    if (authCustomToken) return <Loader />

    return children
}
