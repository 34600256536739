import { datadogRum } from '@datadog/browser-rum'
import * as process from 'process'

export const dataDog = (service: string) => {
    if (process.env.NEXT_PUBLIC_ENVIRONMENT === 'production') {
        datadogRum.init({
            applicationId: process.env.NEXT_PUBLIC_DD_APPLICATION_ID ?? '',
            clientToken: process.env.NEXT_PUBLIC_DD_CLIENT_TOKEN ?? '',
            site: 'datadoghq.eu',
            service,
            env: process.env.NEXT_PUBLIC_ENVIRONMENT ?? 'local',
            version: process.env.NEXT_PUBLIC_DD_VERSION,
            sessionSampleRate: 100,
            sessionReplaySampleRate: 10,
            trackUserInteractions: true,
            trackResources: true,
            trackLongTasks: true,
            defaultPrivacyLevel: 'mask-user-input',
            allowedTracingUrls: [/https:\/\/(?!proxy\.).*\.enter\.de/],
            beforeSend: (event) => {
                if (event.type === 'error' && event?.error?.handling === 'handled') {
                    return false
                }
                return true
            },
        })
    }
}

export const setUserSession = ({ id, name, email }: { id: string; name: string; email: string }) => {
    datadogRum.setUser({
        id,
        name,
        email,
    })
}

const localStorageEnterUserInformation = 'enter_user_information'

const saveUserInformation = (userInfo: { id: string; name: string; email: string }) => {
    window.localStorage.setItem(localStorageEnterUserInformation, JSON.stringify(userInfo))
}

export const getUserInformation = () => {
    return window.localStorage.getItem(localStorageEnterUserInformation) || undefined
}

export const sendUserInformationToDataDog = () => {
    const userInfo = getUserInformation()

    if (!userInfo) return

    setUserSession(JSON.parse(userInfo))
}

export const saveAndSendUserInformationToDataDog = (userInfo: { id: string; name: string; email: string }) => {
    saveUserInformation(userInfo)
    setUserSession(userInfo)
}

export const trackEvent = (eventName: string, eventProperties: Record<string, unknown> = {}) => {
    datadogRum.addAction(eventName, eventProperties)
}
